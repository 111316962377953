const ROUTE_PATH = {
  // PUBLIC
  PUBLIC: {
    HOME: "/home",
    PRIVACY_POLICY: "privacy-policy",
    TERMS_CONDITIONS: "terms-conditions",
    ABOUT_US: "about-us",
    FAQ: "faq",
    TRADE_FINANCE_DESK: "http://www.siesta.money/",
    SOLUTIONS: "solutions",
    SEND_MONEY_ABROAD: {
      MAIN: "/send-money-abroad",
      SENDER_DETAILS: "/send-money-abroad/sender-details",
      RECEIVER_DETAILS: "/send-money-abroad/receiver-details",
      CHECKLIST: "/send-money-abroad/checklist",
      ORDER_REVIEW: "/send-money-abroad/order-review",
      PAYMENT_MODE: "/send-money-abroad/payment-mode",
      ORDER_RECEIVED: "/send-money-abroad/order-received",
    },
    CURRENCY_EXCHANGE: {
      MAIN: "/currency-exchange",
      SELECT_LOCATION: "/currency-exchange/select-location",
      CUSTOMER_DETAILS: "/currency-exchange/customer-details",
      CHECKLIST: "/currency-exchange/checklist",
      ORDER_REVIEW: "/currency-exchange/order-review",
      PAYMENT_MODE: "/currency-exchange/payment-mode",
      ORDER_RECEIVED: "/currency-exchange/order-received",
    },
    TRAVEL_CARD: {
      MAIN: "/travel-card",
      SELECT_LOCATION: "/travel-card/select-location",
      CUSTOMER_DETAILS: "/travel-card/customer-details",
      CHECKLIST: "/travel-card/checklist",
      ORDER_REVIEW: "/travel-card/order-review",
      PAYMENT_MODE: "/travel-card/payment-mode",
      ORDER_RECEIVED: "/travel-card/order-received",
    },
    ORDER_RECEIVED : "customer-order-received",
    ENTER_OTP : "enter-otp",
    VERIFY_MOBILE_NUMBER : "verify-mobile-number",
    UPLOAD_KYC_DETAILS : "upload-kyc-details",
    ORDER_REVIEW : "order-review",
    NEW_LANDING : "new-landing",
    SENDER_DETAILS : "sender-details",
    SENDER_DETAILS2 : "sender-details2",
    WHAT_WE_DO : "what-we-do",
    B2B_LANDING: "b2b-landing",
  },

  // AUTH
  AUTH: {
    MAIN: "/auth",
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    OTP: "/auth/otp",
  },

  //PRIVATE
  PRIVATE: {
    MAIN: "/app/:userType",
    GENERAL: { DASHBOARD: "dashboard" },
    ADMIN: {
      INDEX: "/app/admin",
      MANAGE_USERS: {
        MAIN: "manage-users",
        EDIT_USER: "manage-users/edit/:userId",
      },
      NEW_USER: { MAIN: "new-user", DETAILS: "new-user/:userId" },
      ARCHIVED_USERS: "archived-users",
      MANAGE_EXCHANGE_RATES: "manage-exchange-rates",
      MANAGE_REPORTS: "manage-reports",
      MANAGE_ORDERS: "manage-orders",
      MASTER_TABLES: "master-tables",
      PARTNER_BANK_ALLOCATION: "partner-bank-allocation",
      CREATE_ADMIN_USERS: "create-admin-users",
      SETTINGS: {
        MAIN: "settings",
        MY_PROFILE: "settings/my-profile",
        CHANGE_PASSWORD: "settings/change-password",
        CHANNEL_PARTNER_GROUP: "settings/channel-partner-group",
      },
      MANAGE_AGENTS: "manage-agents",
      MANAGE_FOREX_PARTNER: "manage-fx-partner",
      MANAGE_AGENT_FX: "manage-agent-fx",
      MANAGE_OTP_USERS:"manage-otp"
    },
    STAFF: {
      INDEX: "/app/staff",
      MANAGE_USERS: {
        MAIN: "manage-users",
        EDIT_USER: "manage-users/edit/:userId",
      },
      MANAGE_ORDERS: "manage-orders",
      SETTINGS: {
        MAIN: "settings",
        MY_PROFILE: "settings/my-profile",
        CHANGE_PASSWORD: "settings/change-password",
      },
    },
    CUSTOMER: {
      DASHBOARD: "dashboard",
      INDEX: "/app/customer",
      SEND_MONEY_ABROAD: {
        MAIN: "send-money-abroad",
        ALL_RECEIVERS: "send-money-abroad/all-receivers",
        NEW_FUND_TRANSFER: {
          MAIN: "send-money-abroad/new-fund-transfer",
          SELECT_RECEIVER_LIST:
            "send-money-abroad/new-fund-transfer/select-receiver",
          SELECT_RECEIVER_TYPE:
            "send-money-abroad/new-fund-transfer/select-receiver-type",
          SENDER_DETAILS: "send-money-abroad/new-fund-transfer/sender-details",
          RECEIVER_DETAILS:
            "send-money-abroad/new-fund-transfer/receiver-details",
          CHECKLIST: "send-money-abroad/new-fund-transfer/checklist",
          ORDER_REVIEW: "send-money-abroad/new-fund-transfer/order-review",
          PAYMENT_MODE: "send-money-abroad/new-fund-transfer/payment-mode",
          ORDER_RECEIVED: "send-money-abroad/new-fund-transfer/order-received",
          KYC_DETAILS:"new-fund-transfer/kyc-details",
          BLOCK_LIVE_RATE:"new-fund-transfer/block-live-rate",
          ADD_KYC_DETAILS:"new-fund-transfer/kyc-details/add-kyc-details",
          NEW_ORDER_REVIEW:"new-fund-transfer/new-order-review",
          NEW_SENDER_DETAILS: "send-money-abroad/new-fund-transfer/new-sender-details",
          NEW_SENDER_DETAILS2: "send-money-abroad/new-fund-transfer/new-sender-details/sender-details2",
          WHAT_WE_DOS: "what-we-dos",
        },
        CREATE_RECEIVER: "send-money-abroad/create-receiver",
      },
      CURRENCY_EXCHANGE: {
        MAIN: "currency-exchange",
        RECENT_EXCHANGES: "currency-exchange/recent-exchanges",
        SELECT_PURPOSE_CURRENCY: "currency-exchange/select-purpose-currency",
        SELECT_LOCATION: "currency-exchange/select-location",
        CUSTOMER_DETAILS: "currency-exchange/customer-details",
        CHECKLIST: "currency-exchange/checklist",
        ORDER_REVIEW: "currency-exchange/order-review",
        PAYMENT_MODE: "currency-exchange/payment-mode",
        ORDER_RECEIVED: "currency-exchange/order-received",
      },
      TRAVEL_CARD: {
        MAIN: "travel-card",
        SELECT_LOCATION: "travel-card/select-location",
        CUSTOMER_DETAILS: "travel-card/customer-details",
        CHECKLIST: "travel-card/checklist",
        ORDER_REVIEW: "travel-card/order-review",
        PAYMENT_MODE: "travel-card/payment-mode",
        ORDER_RECEIVED: "travel-card/order-received",
      },
      RATE_ALERTS: "rate-alerts",
      SETTINGS: {
        MAIN: "settings",
        CHANGE_PASSWORD: "settings/change-password",
      },
      NOTIFICATIONS: "notifications",
    },
    AGENT: {
      DASHBOARD: "dashboard",
      INDEX: "/app/agent",
      MANAGE_ORDERS: "manage-orders",
      MANAGE_USERS: "manage-users",
      MANAGE_FX_RATE: "manage-fx-rate",
      SETTINGS: {
        MAIN: "settings",
        AGENT_PROFILE: "settings/agent-profile",
        AFFILIATE_LINKS: "settings/affiliate-links",
        TERMS_AND_CONDITIONS: "settings/terms-and-conditions",
        CHANGE_PASSWORD: "settings/change-password",
      },
      NOTIFICATIONS: "notifications",
      PLACE_AN_ORDER: {
        MAIN: "place-an-order",
        SEND_MONEY_ABROAD: {
          MAIN: "place-an-order/send-money-abroad",
          ALL_RECEIVERS: "place-an-order/send-money-abroad/all-receivers",
          CREATE_RECEIVER: "place-an-order/send-money-abroad/create-receiver",
          NEW_FUND_TRANSFER: {
            MAIN: "place-an-order/send-money-abroad/new-fund-transfer",
            SELECT_RECEIVER_LIST:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver",
            SELECT_RECEIVER_TYPE:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver-type",
            PURPOSE_CURRENCY:
              "place-an-order/send-money-abroad/new-fund-transfer/purpose-currency",
            SENDER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/sender-details",
            RECEIVER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/receiver-details",
            CHECKLIST:
              "place-an-order/send-money-abroad/new-fund-transfer/checklist",
            ORDER_REVIEW:
              "place-an-order/send-money-abroad/new-fund-transfer/order-review",
            PAYMENT_MODE:
              "place-an-order/send-money-abroad/new-fund-transfer/payment-mode",
            ORDER_RECEIVED:
              "place-an-order/send-money-abroad/new-fund-transfer/order-received",
          },
        },
        CURRENCY_EXCHANGE: {
          MAIN: "place-an-order/currency-exchange",
          SELECT_PURPOSE_CURRENCY: "place-an-order/currency-exchange/select-purpose-currency",
          SELECT_LOCATION: "place-an-order/currency-exchange/select-location",
          CUSTOMER_DETAILS: "place-an-order/currency-exchange/customer-details",
          CHECKLIST: "place-an-order/currency-exchange/checklist",
          ORDER_REVIEW: "place-an-order/currency-exchange/order-review",
          PAYMENT_MODE: "place-an-order/currency-exchange/payment-mode",
          ORDER_RECEIVED: "place-an-order/currency-exchange/order-received",
        },
        TRAVEL_CARD: {
          MAIN: "place-an-order/travel-card",
          SELECT_LOCATION: "place-an-order/travel-card/select-location",
          CUSTOMER_DETAILS: "place-an-order/travel-card/customer-details",
          CHECKLIST: "place-an-order/travel-card/checklist",
          ORDER_REVIEW: "place-an-order/travel-card/order-review",
          PAYMENT_MODE: "place-an-order/travel-card/payment-mode",
          ORDER_RECEIVED: "place-an-order/travel-card/order-received",
        },
      },
    },
    BRANCH: {
      DASHBOARD: "dashboard",
      INDEX: "/app/agentBranch",
      MANAGE_ORDERS: "manage-orders",
      MANAGE_USERS: "manage-users",
      MANAGE_FX_RATE: "manage-fx-rate",
      SETTINGS: {
        MAIN: "settings",
        AGENT_PROFILE: "settings/agent-profile",
        AFFILIATE_LINKS: "settings/affiliate-links",
        TERMS_AND_CONDITIONS: "settings/terms-and-conditions",
        CHANGE_PASSWORD: "settings/change-password",
      },
      NOTIFICATIONS: "notifications",
      PLACE_AN_ORDER: {
        MAIN: "place-an-order",
        SEND_MONEY_ABROAD: {
          MAIN: "place-an-order/send-money-abroad",
          ALL_RECEIVERS: "place-an-order/send-money-abroad/all-receivers",
          CREATE_RECEIVER: "place-an-order/send-money-abroad/create-receiver",
          NEW_FUND_TRANSFER: {
            MAIN: "place-an-order/send-money-abroad/new-fund-transfer",
            SELECT_RECEIVER_LIST:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver",
            SELECT_RECEIVER_TYPE:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver-type",
            PURPOSE_CURRENCY:
              "place-an-order/send-money-abroad/new-fund-transfer/purpose-currency",
            SENDER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/sender-details",
            RECEIVER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/receiver-details",
            CHECKLIST:
              "place-an-order/send-money-abroad/new-fund-transfer/checklist",
            ORDER_REVIEW:
              "place-an-order/send-money-abroad/new-fund-transfer/order-review",
            PAYMENT_MODE:
              "place-an-order/send-money-abroad/new-fund-transfer/payment-mode",
            ORDER_RECEIVED:
              "place-an-order/send-money-abroad/new-fund-transfer/order-received",
          },
        },
        CURRENCY_EXCHANGE: {
          MAIN: "place-an-order/currency-exchange",
          SELECT_PURPOSE_CURRENCY: "place-an-order/currency-exchange/select-purpose-currency",
          SELECT_LOCATION: "place-an-order/currency-exchange/select-location",
          CUSTOMER_DETAILS: "place-an-order/currency-exchange/customer-details",
          CHECKLIST: "place-an-order/currency-exchange/checklist",
          ORDER_REVIEW: "place-an-order/currency-exchange/order-review",
          PAYMENT_MODE: "place-an-order/currency-exchange/payment-mode",
          ORDER_RECEIVED: "place-an-order/currency-exchange/order-received",
        },
        TRAVEL_CARD: {
          MAIN: "place-an-order/travel-card",
          SELECT_LOCATION: "place-an-order/travel-card/select-location",
          CUSTOMER_DETAILS: "place-an-order/travel-card/customer-details",
          CHECKLIST: "place-an-order/travel-card/checklist",
          ORDER_REVIEW: "place-an-order/travel-card/order-review",
          PAYMENT_MODE: "place-an-order/travel-card/payment-mode",
          ORDER_RECEIVED: "place-an-order/travel-card/order-received",
        },
      },
    },
    AGENT_BUSINSS_BRANCH: {
      DASHBOARD: "dashboard",
      INDEX: "/app/agentBusinessPartner",
      MANAGE_ORDERS: "manage-orders",
      MANAGE_USERS: "manage-users",
      MANAGE_FX_RATE: "manage-fx-rate",
      SETTINGS: {
        MAIN: "settings",
        AGENT_PROFILE: "settings/agent-profile",
        AFFILIATE_LINKS: "settings/affiliate-links",
        TERMS_AND_CONDITIONS: "settings/terms-and-conditions",
        CHANGE_PASSWORD: "settings/change-password",
      },
      NOTIFICATIONS: "notifications",
      PLACE_AN_ORDER: {
        MAIN: "place-an-order",
        SEND_MONEY_ABROAD: {
          MAIN: "place-an-order/send-money-abroad",
          ALL_RECEIVERS: "place-an-order/send-money-abroad/all-receivers",
          CREATE_RECEIVER: "place-an-order/send-money-abroad/create-receiver",
          NEW_FUND_TRANSFER: {
            MAIN: "place-an-order/send-money-abroad/new-fund-transfer",
            SELECT_RECEIVER_LIST:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver",
            SELECT_RECEIVER_TYPE:
              "place-an-order/send-money-abroad/new-fund-transfer/select-receiver-type",
            PURPOSE_CURRENCY:
              "place-an-order/send-money-abroad/new-fund-transfer/purpose-currency",
            SENDER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/sender-details",
            RECEIVER_DETAILS:
              "place-an-order/send-money-abroad/new-fund-transfer/receiver-details",
            CHECKLIST:
              "place-an-order/send-money-abroad/new-fund-transfer/checklist",
            ORDER_REVIEW:
              "place-an-order/send-money-abroad/new-fund-transfer/order-review",
            PAYMENT_MODE:
              "place-an-order/send-money-abroad/new-fund-transfer/payment-mode",
            ORDER_RECEIVED:
              "place-an-order/send-money-abroad/new-fund-transfer/order-received",
          },
        },
        CURRENCY_EXCHANGE: {
          MAIN: "place-an-order/currency-exchange",
          SELECT_PURPOSE_CURRENCY: "place-an-order/currency-exchange/select-purpose-currency",
          SELECT_LOCATION: "place-an-order/currency-exchange/select-location",
          CUSTOMER_DETAILS: "place-an-order/currency-exchange/customer-details",
          CHECKLIST: "place-an-order/currency-exchange/checklist",
          ORDER_REVIEW: "place-an-order/currency-exchange/order-review",
          PAYMENT_MODE: "place-an-order/currency-exchange/payment-mode",
          ORDER_RECEIVED: "place-an-order/currency-exchange/order-received",
        },
        TRAVEL_CARD: {
          MAIN: "place-an-order/travel-card",
          SELECT_LOCATION: "place-an-order/travel-card/select-location",
          CUSTOMER_DETAILS: "place-an-order/travel-card/customer-details",
          CHECKLIST: "place-an-order/travel-card/checklist",
          ORDER_REVIEW: "place-an-order/travel-card/order-review",
          PAYMENT_MODE: "place-an-order/travel-card/payment-mode",
          ORDER_RECEIVED: "place-an-order/travel-card/order-received",
        },
      },
    },
    CHANNEL_PARTNER: {
      INDEX: "/app/cpartner",
      DASHBOARD: "dashboard",
      MANAGE_ORDERS: "manage-orders",
      MANAGE_CURRENCIES: "manage-currencies",
      SETTINGS: {
        MAIN: "settings",
        MY_PROFILE: "settings/my-profile",
        CHANGE_PASSWORD: "settings/change-password",
      },
      NOTIFICATIONS: "notifications",
    },
  },

  ERROR: {
    ERROR_403: "/403",
    CATCH_ALL: "*",
  },
};
export default ROUTE_PATH;
